const Constants = {
  images: {
    userProfile: require("../assets/images/user.png"),
    student: require("../assets/images/student.png"),
    parents: require("../assets/images/parents.png"),
    admin: require("../assets/images/admin.png"),
    teacher: require("../assets/images/teacher.png"),
    campusAdmin: require("../assets/images/campus.png"),
  },
  icons: {
    messages: require("../assets/icons/message.png"),
    attendance: require("../assets/icons/instruction.png"),
    behavior: require("../assets/icons/feedback.png"),
    grading: require("../assets/icons/best.png"),
    classNotes: require("../assets/icons/classNotes.png"),
    calendar: require("../assets/icons/calendar.png"),
    studentReports: require("../assets/icons/report.png"),
  },
  key: {
    CookiesKey: {
      loginToken: "login-token",
      loginType: "login-type",
    },
    responseType: {
      valid: "valid",
      success: "success",
    },
    loginType: {
      student: "student",
    },
  },
  colors: {
    halfTransparentBlack: "#02020288",
    lightTheme: {
      palette: {
        mode: "light",
        primary: {
          main: "#0073a8",
        },
        secondary: {
          main: "#017a64",
        },
        success: {
          main: "#00a822",
        },
        warning: {
          main: "#e69d00",
        },
        error: {
          main: "#f00000",
        },
        background: {
          default: "#f5f5f5",
          paper: "#ffffff",
        },
        text: {
          primary: "#333333",
          secondary: "#a8a8a8",
          onPrimary: "#ffffff",
          onSecondary: "#ffdc7a",
        },
      },
    },
    darkTheme: {
      palette: {
        mode: "dark",
        primary: {
          main: "#ffa726",
        },
        secondary: {
          main: "#f57c00",
        },
        background: {
          default: "#222",
          paper: "#333",
        },
        text: {
          primary: "#fff",
          secondary: "#ccc",
        },
      },
    },
  },
  navigationStack: {
    home: "/",
    login: "/login",
    attendance: "/attendance/:eventId",
  },
};

export default Constants;
