import { CalendarViewDay, Edit, NextPlan, Settings } from "@mui/icons-material";
import { Box, Container, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { load } from "react-cookies";
import { useNavigate } from "react-router-dom";
import DetentionListItem from "../components/attendance/detentions-list-item";
import AccountActionTooltip from "../components/dashboard/account-action-tooltip";
import DashboardDrawer from "../components/dashboard/drawer";
import HeaderBar from "../components/header-bar";
import LoadingView from "../components/loading-view";
import UpNext from "../fragments/dashboard/fg-up-next";
import Api from "../utils/Api";
import { HbType, ResponseType, UsersDataType } from "../utils/ConstType";
import Constants from "../utils/Constants";
import EfStyleSheet from "../utils/style-helper";
//cookie.load("login-type");

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [me, setMe] = useState<UsersDataType.AdminType>({
    name: "N/A",
    email: "N/A",
  });
  const [activeFragment, setActiveFragment] = useState<
    "home" | "my-calendar" | "edit-account" | "settings"
  >("home");
  const [title, setTitle] = useState("Dashboard");
  const [isDetentioned, setIsDetentioned] = useState(false);
  const [detentionsList, setDetentionsList] = useState<
    HbType.AttendanceResponseType[]
  >([]);

  const drawerItem = [
    {
      groupTitle: "Campus area",
      items: [
        {
          title: "Up Next",
          onClick: () => {
            setActiveFragment("home");
          },
          iconComponent: <NextPlan />,
        },
        {
          title: "My Calendar",
          onClick: () => {
            setActiveFragment("my-calendar");
          },
          iconComponent: <CalendarViewDay />,
        },
      ],
    },
    {
      groupTitle: "Account",
      items: [
        {
          title: "Edit account",
          onClick: () => {
            setActiveFragment("edit-account");
          },
          iconComponent: <Edit />,
        },
        {
          title: "Settings",
          onClick: () => {
            setActiveFragment("settings");
          },
          iconComponent: <Settings />,
        },
      ],
    },
  ];

  const navigate = useNavigate();

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  React.useEffect(() => {
    // alert(loginToken);

    if (loginToken) {
      Api.handler
        .validate(loginToken)
        .then((response) => {
          const apiResponse: ResponseType = response.data;
          console.log("response is: ", response.data);

          if (apiResponse.status === "success") {
            console.log("valid token: ", apiResponse);
            setMe(apiResponse.message);
            setIsLoading(false);
          } else {
            alert("Error while logging in.");
            navigate(Constants.navigationStack.login);
          }

          // if (response.data) {
          //   setIsLoading(false);
          // } else {
          // }
        })
        .catch((e) => {
          setIsLoading(false);
        });

      functionHandler.checkDetentionStatus();
      const interval = setInterval(
        functionHandler.checkDetentionStatus,
        1000 * 60
      );

      return () => clearInterval(interval);
    } else {
      navigate(Constants.navigationStack.login);
    }
  }, []);

  const functionHandler = {
    checkDetentionStatus() {
      Api.handler.attendance
        .amIDetentioned(loginToken)
        .then((res) => {
          console.log("detention status: ", res.data);
          return res.data as ResponseType;
        })
        .then((res) => {
          if (res.status === "success") {
            try {
              const detns = res.message as HbType.AttendanceResponseType[];
              console.log(detns.length);
              if (detns.length > 0) {
                setIsDetentioned(true);
                setDetentionsList(detns);
              } else setIsDetentioned(false);
            } catch (e) {
              console.log("failed to parse detentions array", res.message);
            }
          }
        })
        .catch((e) => {
          console.log("error getting detention status...", e);
        });
    },
  };
  console.log("detention status: ", isDetentioned);
  const styles = EfStyleSheet.create({
    root: {
      // backgroundColor: isDetentioned ? "#fed1adff" : "transparent",
      minHeight: "100vh",
    },
  });

  return (
    <Box sx={styles.root}>
      {isLoading && <LoadingView />}
      <HeaderBar
        title={title}
        onMenuClick={() => {
          setIsDrawerOpen((o) => !o);
        }}
        onUserNameClick={() => setShowTooltip(true)}
        userName={me.name}
      />
      <DashboardDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        drawerItem={drawerItem}
        setTitle={setTitle}
      />
      {showTooltip && (
        <AccountActionTooltip user={me} onHide={() => setShowTooltip(false)} />
      )}
      <Container>
        {detentionsList.length > 0 && (
          <Box
            sx={{
              p: 1,
              m: 1,
              borderRadius: 4,
              backgroundColor: "#ffffff8b",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ textAlign: "center" }}>
              You have been detentioned from following event's:
            </Typography>

            <Box mt={1} width="100%">
              {detentionsList.map((d) => (
                <DetentionListItem attendance={d} />
              ))}
            </Box>
          </Box>
        )}

        {activeFragment === "home" ? (
          <UpNext isDetentioned={isDetentioned} />
        ) : activeFragment === "edit-account" ? (
          <Box>edit account</Box>
        ) : (
          activeFragment === "settings" && <Box> settings</Box>
        )}
      </Container>
    </Box>
  );
};

export default Dashboard;
