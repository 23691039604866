/* eslint-disable no-useless-escape */
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  CircularProgress,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";

import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  ArrowBack,
  Info,
  Person,
  QrCode2,
  QrCodeScanner,
  Verified,
} from "@mui/icons-material";
import axios from "axios";
import moment from "moment";
import { load } from "react-cookies";
import EventStats from "../components/attendance/event-stats";
import QrCode from "../components/attendance/fg-qr-code";
import LoadingView from "../components/loading-view";
import Api from "../utils/Api";
import { HbType, ResponseType } from "../utils/ConstType";
import Constants from "../utils/Constants";
import EfStyleSheet from "../utils/style-helper";

export default function ActiveEventScreen() {
  const [goBack, setGoback] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [event, setEvent] = useState<HbType.EventResponseType>();
  const [currentLibyaTime, setCurrentLibyaTime] = useState("");
  const [endAt, setEndAt] = useState("");
  const [isSkipDialogOpen, setIsSkipDialogOpen] = useState(false);
  const [attendances, setAttendances] = useState<
    HbType.AttendanceResponseType[]
  >([]);
  const [activeFragment, setActiveFragment] = useState<"present" | "info">(
    "info"
  );

  const navigate = useNavigate();
  const param = useParams();

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );
  useEffect(() => {
    // alert(loginToken);

    if (loginToken) {
      Api.handler
        .validate(loginToken)
        .then((response) => {
          const apiResponse: ResponseType = response.data;

          if (apiResponse.status === "success") {
            setIsLoading(false);
            functionHandler.getEventDetails();
          } else {
            alert("Error while logging in.");
            navigate(Constants.navigationStack.login);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } else {
      navigate(Constants.navigationStack.login);
    }
    return functionHandler.getCurrentLibyaTime();
  }, []);

  useEffect(() => {
    if (goBack) {
      navigate(Constants.navigationStack.home);
    }
  }, [goBack, navigate]);

  useEffect(() => {
    if (event && currentLibyaTime) {
      const timeLeft = moment
        .duration(
          moment(currentLibyaTime, "YYYY-MM-DD hh:mm:A").diff(
            moment(event.end_time, "hh:mm:A")
          )
        )
        .humanize();
      console.log("time left is: ", timeLeft);
      setEndAt(timeLeft);
    }
  }, [currentLibyaTime, event]);

  useEffect(() => {
    functionHandler.getAllAttendance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, activeFragment]);

  const functionHandler = {
    // skipEvent() {
    //   if (attendances[0]?.id) {
    //     setIsSkipDialogOpen(false);
    //     setIsLoading(true);
    //     const a = attendances[0];

    //     Api.handler.attendance
    //       .update(a.event_id, a.grade_id, a.subject_id, "skipped", loginToken)
    //       .then((res) => {
    //         const r = res.data as ResponseType;
    //         if (r.status === "success") {
    //           setAttendances((o) => {
    //             return o.map((x) => {
    //               if (x.id === a.id) {
    //                 return {
    //                   ...x,
    //                   status: "skipped",
    //                 };
    //               } else return x;
    //             });
    //           });
    //         } else {
    //           setError("Error: " + r.message);
    //         }
    //       })
    //       .catch((e) => {
    //         setError("Error: " + e);
    //       })
    //       .finally(() => {
    //         setIsLoading(false);
    //       });
    //   } else {
    //     setError("Invalid attendance data.");
    //   }
    // },
    getAllAttendance() {
      if (event) {
        Api.handler.attendance
          .getAll(loginToken, event.id)
          .then((r) => {
            console.log("response of attendance is: ", r.data);
            const res: ResponseType = r.data;
            const attendance = res.message as HbType.AttendanceResponseType[];
            setAttendances(attendance);
          })
          .catch((e) => {
            console.log("failed getting response of attendacne. reason: ", e);
          })
          .finally(() => {});
      }
    },
    getCurrentLibyaTime: () => {
      axios
        .get(Api.endpoints.libyaTime)
        .then((res) => {
          setCurrentLibyaTime(res.data);
        })
        .catch((e) => {});
      const cancelInterval = setInterval(() => {
        axios
          .get(Api.endpoints.libyaTime)
          .then((res) => {
            setCurrentLibyaTime(res.data);
          })
          .catch((e) => {});
      }, 55 * 1000);

      return () => clearInterval(cancelInterval);
    },
    getEventDetails: () => {
      setError("");
      setIsLoading(true);
      console.log("getting event details of event: ", param.eventId);
      Api.handler.event
        .getActive(loginToken, Number(param.eventId))
        .then((res) => {
          console.log("event response : ", res.data);
          const r = res.data as ResponseType;
          const active = r.message.active as HbType.EventResponseType[];
          const next = r.message.next as HbType.EventResponseType[];
          if (active?.length > 0) {
            setEvent(active[0]);
          }
        })
        .catch((e) => {
          console.log("error on getting event: ", e);
          setError(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  };

  const styles = EfStyleSheet.create({
    toobar: {
      alignItems: "center",
      justifyContent: "flex-start",
      gap: 1,
    },
    backButton: { color: Constants.colors.lightTheme.palette.text.onPrimary },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    topEndIndicator: {
      backgroundColor:
        Constants.colors.lightTheme.palette.warning.main +
        (activeFragment === "info" ? "e0" : "40"),
      border: `1px solid ${Constants.colors.lightTheme.palette.warning.main}60`,
      borderRadius: 4,
      m: 1,
      p: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "fit-content",
      alignSelf: "center",
      color: "#fff",
    },
  });

  const MyAppBar = () => {
    return (
      <AppBar position="sticky">
        <Toolbar sx={styles.toobar}>
          <IconButton
            sx={styles.backButton}
            onClick={() => {
              setGoback(true);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography sx={{ textTransform: "capitalize", fontWeight: "bold" }}>
            {event ? event.title : "Unknown"}
          </Typography>
        </Toolbar>
      </AppBar>
    );
  };
  const TopEndTimeIndicator = () => (
    <Box sx={styles.topEndIndicator}>
      {/* <Typography>{currentLibyaTime}</Typography> */}

      <Typography>
        This feature won't be available after{" "}
        <b
          style={{
            color: Constants.colors.lightTheme.palette.text.onSecondary,
          }}
        >
          {endAt}
        </b>{" "}
        at{" "}
        <b
          style={{
            color: Constants.colors.lightTheme.palette.text.onSecondary,
          }}
        >
          {" "}
          {event?.end_time}{" "}
        </b>
        based on Libya Standard Time.
      </Typography>
    </Box>
  );

  const absent =
    attendances && attendances[0] && attendances[0]?.status === "absent";
  const present =
    attendances && attendances[0] && attendances[0]?.status === "present";
  const late =
    attendances && attendances[0] && attendances[0]?.status === "late";
  const skipped =
    attendances && attendances[0] && attendances[0]?.status === "skipped";
  const showQr = true;
  return (
    <>
      {isLoading && <LoadingView />}
      <Box
        minHeight="100vh"
        bgcolor={
          absent
            ? Constants.colors.lightTheme.palette.error.main + "20"
            : late
            ? Constants.colors.lightTheme.palette.warning.main + "20"
            : skipped
            ? Constants.colors.lightTheme.palette.text.secondary + "20"
            : Constants.colors.lightTheme.palette.success.main + "20"
        }
      >
        <MyAppBar />
        <Container sx={styles.container}>
          <TopEndTimeIndicator />
          {event ? (
            activeFragment === "info" ? (
              <Box>
                <EventStats event={event as any} />

                {
                  !absent && (
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      gap={1}
                      height="100%"
                    >
                      <Box position="relative">
                        {attendances[0]?.present_by === "qr" ? (
                          <QrCode2 sx={{ fontSize: 122, color: "#ddd" }} />
                        ) : (
                          <Person sx={{ fontSize: 122, color: "#ddd" }} />
                        )}
                        <Verified
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                            fontSize: 48,
                            color: "green",
                          }}
                        />
                      </Box>
                      <Typography
                        sx={{ textTransform: "uppercase", color: "black" }}
                        variant="h6"
                      >
                        {attendances[0]?.status || "Unknown"}
                      </Typography>

                      <Typography>
                        Updated by {attendances[0]?.present_by}
                      </Typography>
                    </Box>
                  )
                  // :
                  //  (
                  //   <Box
                  //     width="100%"
                  //     display="flex"
                  //     alignItems="center"
                  //     justifyContent="center"
                  //     flexDirection="column"
                  //     gap={1}
                  //     height="100%"
                  //     mt={8}
                  //   >
                  //     <Button
                  //       sx={{
                  //         padding: 2,
                  //         bgcolor:
                  //           Constants.colors.lightTheme.palette.text.secondary,
                  //         color: "yellow",
                  //         "&:hover": {
                  //           color:
                  //             Constants.colors.lightTheme.palette.primary.main,
                  //         },
                  //       }}
                  //       variant="outlined"
                  //       onClick={() => {
                  //         setIsSkipDialogOpen(true);
                  //       }}
                  //     >
                  //       Skip Class
                  //     </Button>
                  //   </Box>
                  // )
                }

                {attendances && attendances[0]?.detentioned === "yes" && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 4,
                      gap: 1,
                    }}
                  >
                    <Typography>Detentioned</Typography>
                  </Box>
                )}
              </Box>
            ) : activeFragment === "present" ? (
              <Box
                bgcolor="#000000f0"
                width="100%"
                height="100%"
                position="absolute"
                top={0}
                left={0}
                zIndex={-1}
              >
                <QrCode
                  attendances={attendances}
                  event={event as any}
                  onDoneValidation={() => functionHandler.getAllAttendance()}
                />
              </Box>
            ) : (
              <Box>
                <Typography>Screen under construction</Typography>
              </Box>
            )
          ) : (
            <CircularProgress />
          )}
        </Container>

        {/* <Dialog
          open={isSkipDialogOpen}
          onClose={() => setIsSkipDialogOpen(false)}
        >
          <DialogTitle>Warning !</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure, you want to skip the class?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={functionHandler.skipEvent}>Skip</Button>
            <Button onClick={() => setIsSkipDialogOpen(false)}>Cancel</Button>
          </DialogActions>
        </Dialog> */}

        <BottomNavigation
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
          }}
          showLabels
          value={activeFragment}
          onChange={(event, newValue) => {
            setActiveFragment(newValue);
          }}
        >
          <BottomNavigationAction label="Info" value="info" icon={<Info />} />
          {showQr && (
            <BottomNavigationAction
              label="Present"
              value="present"
              icon={<QrCodeScanner />}
            />
          )}
        </BottomNavigation>
      </Box>
    </>
  );
}
