import { Avatar, Box, Typography } from "@mui/material";
import { useState } from "react";
import { HbType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";

export default function EventStats({
  event,
}: {
  event: HbType.EventResponseType;
}) {
  const [endAt, setEndAt] = useState<string>("Today");

  event.days_of_week = Object.fromEntries(
    Object.entries(event.days_of_week).sort((a, b) => {
      const order = ["su", "mo", "tu", "we", "th", "fr", "sa"];
      return order.indexOf(a[0]) - order.indexOf(b[0]);
    })
  ) as any;
  const dbSpl = event.start_time.split(":");
  const dbEpl = event.end_time.split(":");

  event.start_time =
    dbSpl[0] +
    ":" +
    (dbSpl[1].length === 1 ? "0" + dbSpl[1] : dbSpl[1]) +
    ":" +
    dbSpl[2];

  event.end_time =
    dbEpl[0] +
    ":" +
    (dbEpl[1].length === 1 ? "0" + dbEpl[1] : dbEpl[1]) +
    ":" +
    dbEpl[2];

  return (
    <Box
      p={0.5}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      <Box
        sx={{
          margin: 0.3,
          width: "100%",
          minWidth: 200,
          height: "100%",
          // border: "1px solid black",
          padding: "4px 8px",
          borderRadius: 2,
          //   backgroundColor: Constants.colors.lightTheme.palette.background.paper,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          width="100%"
          display="flex"
          gap={0}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box width="100%">
            {/* event details area  */}{" "}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Avatar
                sx={{
                  bgcolor: Constants.colors.lightTheme.palette.secondary.main,
                }}
              >
                <b>{event.subjectName.substring(0, 1)}</b>
              </Avatar>
              <Typography
                noWrap
                fontSize={16}
                sx={{ fontWeight: "bold" }}
                variant="subtitle1"
                color={Constants.colors.lightTheme.palette.text.primary}
              >
                {event.subjectName}
              </Typography>
              <Typography
                noWrap
                fontSize={18}
                sx={{ fontWeight: "bold" }}
                variant="subtitle2"
                color={Constants.colors.lightTheme.palette.primary.main}
                fontWeight="bold"
              >
                {event.title}
              </Typography>
              <Typography
                fontSize={14}
                sx={{ fontWeight: "bold" }}
                variant="subtitle1"
                color={Constants.colors.lightTheme.palette.text.primary}
              >
                For
                <span
                  style={{
                    marginLeft: 4,
                    marginRight: 4,
                    color: Constants.colors.lightTheme.palette.text.onSecondary,
                    backgroundColor:
                      Constants.colors.lightTheme.palette.secondary.main,
                    padding: "2px 8px",
                    borderRadius: 16,
                  }}
                >
                  {event.gradeName}
                </span>
                in
                <span
                  style={{
                    color: Constants.colors.lightTheme.palette.text.onSecondary,
                    backgroundColor:
                      Constants.colors.lightTheme.palette.secondary.main,
                    padding: "2px 8px",
                    marginLeft: 4,
                    marginRight: 4,
                    borderRadius: 16,
                  }}
                >
                  {event.className}
                </span>
              </Typography>
              <Box
                height="100%"
                paddingTop={1}
                minHeight="100%"
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
              >
                <Typography
                  color={Constants.colors.lightTheme.palette.secondary.main}
                  fontSize={14}
                  sx={{
                    WebkitLineClamp: 4,
                    overflow: "hidden",
                    textAlign: "center",
                    overflowWrap: "anywhere",
                  }}
                >
                  {event.description}
                </Typography>
              </Box>
              {event.type === "once" ? (
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                  paddingTop={1}
                  justifyContent={"center"}
                  gap={0.5}
                  flexWrap="wrap"
                >
                  <Typography noWrap fontSize={10}>
                    at
                  </Typography>
                  <Typography
                    noWrap
                    textTransform="uppercase"
                    bgcolor={Constants.colors.lightTheme.palette.warning.main}
                    color={Constants.colors.lightTheme.palette.text.primary}
                    p="2px 6px"
                    borderRadius={4}
                    fontSize={10}
                    boxShadow="1px 2px 5px 2px #ddd"
                  >
                    {event.start_date}
                  </Typography>
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="row"
                  paddingTop={1}
                  gap={0.5}
                  flexWrap="wrap"
                >
                  <Box>
                    <Typography
                      noWrap
                      textTransform="uppercase"
                      bgcolor={
                        Constants.colors.lightTheme.palette.secondary.main +
                        "f0"
                      }
                      color={Constants.colors.lightTheme.palette.text.onPrimary}
                      p="2px 6px"
                      mt={1}
                      mb={1}
                      borderRadius={4}
                      fontSize={10}
                      boxShadow="1px 2px 5px 2px #ddd"
                    >
                      <b
                        style={{
                          color:
                            Constants.colors.lightTheme.palette.text
                              .onSecondary,
                        }}
                      >
                        {event.type}
                      </b>{" "}
                      {/* event duration */}
                      {event.full_day_event === "no" &&
                        event.start_time + " - " + event.end_time}
                    </Typography>

                    {event.full_day_event === "yes" && (
                      <Typography
                        noWrap
                        textTransform="uppercase"
                        bgcolor={
                          Constants.colors.lightTheme.palette.secondary.main +
                          "f0"
                        }
                        color={
                          Constants.colors.lightTheme.palette.text.onPrimary
                        }
                        p="2px 8px"
                        m={1}
                        variant="h6"
                        borderRadius={4}
                        fontSize={10}
                        boxShadow="1px 2px 5px 2px #ddd"
                      >
                        <b>FULL DAY EVENT</b>
                      </Typography>
                    )}
                  </Box>
                  <Typography noWrap fontSize={10}>
                    from
                  </Typography>
                  <Typography
                    noWrap
                    textTransform="uppercase"
                    bgcolor={Constants.colors.lightTheme.palette.warning.main}
                    color={Constants.colors.lightTheme.palette.text.primary}
                    p="2px 6px"
                    borderRadius={4}
                    fontSize={10}
                    boxShadow="1px 2px 5px 2px #ddd"
                  >
                    {event.start_date}
                  </Typography>
                  <Typography noWrap fontSize={10}>
                    to
                  </Typography>
                  <Typography
                    noWrap
                    textTransform="uppercase"
                    bgcolor={Constants.colors.lightTheme.palette.warning.main}
                    color={Constants.colors.lightTheme.palette.text.primary}
                    p="2px 6px"
                    borderRadius={4}
                    fontSize={10}
                    boxShadow="1px 2px 5px 2px #ddd"
                  >
                    {event.end_date}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
