/* eslint-disable react-hooks/exhaustive-deps */
import {
  Person,
  QrCode2,
  Verified,
  Warning,
  WarningAmber,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MD5 } from "crypto-js";
import { useEffect, useMemo, useState } from "react";
import { load } from "react-cookies";
import { QrReader } from "react-qr-reader";
import Api from "../../utils/Api";
import { HbType, ResponseType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";

export default function QrCode({
  event,
  attendances,
  onDoneValidation,
}: {
  event: HbType.EventResponseType;
  attendances: HbType.AttendanceResponseType[];
  onDoneValidation: () => void;
}) {
  const [error, setError] = useState("");
  const [isValidating, setIsValidating] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  useEffect(() => {}, [event, attendances]);

  //   console.log("my value is: ", mv);

  const functionHandler = {
    validateQr(qrValue: string) {
      if (qrValue.length > 0) {
        const {
          teacherName,
          className,
          gradeName,
          subjectName,
          ...serverValue
        } = event;
        const hashIs = MD5(JSON.stringify(serverValue)).toString();
        console.log("qr compare: ", qrValue, hashIs);

        setError("");
        if (qrValue === hashIs) {
          setIsValidating(true);
          Api.handler.attendance
            .update(
              event.id,
              event.grade_id,
              event.subject_id,
              qrValue,
              loginToken
            )
            .then((res) => {
              console.log("got response validating qr code", res.data);
              const response = res.data as ResponseType;
              if (response.status !== "success") {
                setError(response.message);
              } else {
                onDoneValidation();
              }
            })
            .catch((e) => {
              console.log("error validating QR code.");
              setError(e);
            });
        } else {
          console.log("qr code is not for current event");
          setError(
            "Invalid QR code. This QR code is not generated for: " + event.title
          );
        }
      } else {
        console.log("trying to validate qr using null or empty value");
      }
    },
  };
  if (attendances.length === 0) {
    return (
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={1}
      >
        <Avatar>
          <Warning />
        </Avatar>
        <Typography>No Data Found!</Typography>
      </Box>
    );
  }

  console.log("attendances is: ", attendances);
  if (attendances[0].status !== "absent") {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={1}
      >
        <Box position="relative">
          {attendances[0].present_by === "qr" ? (
            <QrCode2 sx={{ fontSize: 182 }} />
          ) : (
            <Person sx={{ fontSize: 182 }} />
          )}
          <Verified
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              fontSize: 68,
              color: "green",
            }}
          />
        </Box>
        <Typography
          sx={{ textTransform: "uppercase", color: "white" }}
          variant="h6"
        >
          {attendances[0]?.status}
        </Typography>
        <Typography>Updated by {attendances[0]?.present_by}</Typography>
      </Box>
    );
  }
  return (
    <Box width="100%" height="100vh">
      {isValidating ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap={1}
        >
          {error ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              gap={2}
            >
              <WarningAmber sx={{ fontSize: 48, color: "white" }} />
              <Button
                variant="outlined"
                color="warning"
                onClick={() => setIsValidating(false)}
              >
                Retry
              </Button>
            </Box>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              gap={2}
            >
              <CircularProgress color="warning" />
              <Typography color="white">Validating...</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box height="100%" width="100%">
          <QrReader
            containerStyle={{
              width: "100%",
              height: "100%",
            }}
            videoContainerStyle={{
              width: "100%",
              height: "100%",
              display: "flex",
            }}
            videoStyle={{
              flexGrow: 1,
              objectFit: "cover",
            }}
            onResult={(result, error) => {
              if (result) {
                // console.log("result of qr is: ", result);
                functionHandler.validateQr((result as any).text);
              }

              if (!!error) {
                setError("Please position the QR code accordingly.");
              }
            }}
            scanDelay={5000}
            constraints={{ facingMode: "environment" }}
          />
          {isMobile ? (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                border: "2px solid red",
                boxSizing: "border-box",
                width: "50%",
                paddingBottom: "50%", // Creates a square by setting padding bottom to the same percentage as width
              }}
            />
          ) : (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                border: "2px solid red",
                boxSizing: "border-box",
                height: "50%",
                paddingRight: "50%", // Creates a square by setting padding bottom to the same percentage as width
              }}
            />
          )}
        </Box>
      )}

      {error && (
        <Box
          sx={{
            position: "absolute",
            bottom: 88,
            margin: 1,
            backgroundColor: "#ffffffc2",
            border: "1px solid #ddd",
            borderRadius: 2,
            padding: "2px 12px",
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            right: 0,
          }}
        >
          <Typography sx={{ textAlign: "center" }}>{error}</Typography>
        </Box>
      )}
    </Box>
  );
}
