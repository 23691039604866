import Menu from "@mui/icons-material/Menu";
import { AppBar, Button, IconButton, Toolbar, Typography } from "@mui/material";

export default function HeaderBar({
  onMenuClick = () => {},
  title = "Ismly Admin",
  userName = "N/A",
  onUserNameClick = () => {},
}: {
  onMenuClick?: () => void;
  title?: string;
  userName: string;
  onUserNameClick: () => void;
}) {
  return (
    <AppBar position="sticky" sx={{ zIndex: 999999 }}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          sx={{ mr: 2 }}
          onClick={onMenuClick}
        >
          <Menu sx={{ color: "white" }} />
        </IconButton>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            flexGrow: 1,
            //  width: { xs: "none", sm: "block" }
          }}
        >
          {title}
        </Typography>
        <Button
          variant="text"
          sx={{ color: "#ebc700" }}
          onClick={onUserNameClick}
        >
          {userName}
        </Button>
      </Toolbar>
    </AppBar>
  );
}
