import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import ActiveEventScreen from "./screens/active-event";
import Dashboard from "./screens/dashboard";
import LoginScreen from "./screens/login";
import Constants from "./utils/Constants";

// Light Theme

// Dark Theme

const themeData: any = Constants.colors.lightTheme;

const theme = createTheme(themeData);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Dashboard />} />
          <Route path="/" element={<Dashboard />} />
          <Route
            path={Constants.navigationStack.login}
            element={<LoginScreen />}
          />
          <Route
            path={Constants.navigationStack.attendance}
            element={<ActiveEventScreen />}
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
