import { Warning } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

export default function NoActiveEvent() {
  return (
    <Box
      pt="5vh"
      pb="5vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Warning sx={{ fontSize: 48 }} />
      <Typography p={2}>No active events !</Typography>
    </Box>
  );
}
