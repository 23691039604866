import { Box, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { load } from "react-cookies";
import ActiveEvent from "../../components/dashboard/fg-up-next/active-event";
import NoActiveEvent from "../../components/dashboard/fg-up-next/no-active-event";
import NoUpcomingEvent from "../../components/dashboard/fg-up-next/no-upcoming-event";
import UpcomingEvent from "../../components/dashboard/fg-up-next/upcomint-event";
import LoadingView from "../../components/loading-view";
import Api from "../../utils/Api";
import { HbType, ResponseType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";

export default function UpNext({ isDetentioned }: { isDetentioned: boolean }) {
  const [isLoading, setIsLoading] = useState(false);
  const [activeEvents, setActiveEvents] = useState<HbType.EventResponseType[]>(
    []
  );
  const [upNextEvent, setUpnextEvent] = useState<HbType.EventResponseType[]>(
    []
  );

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  useEffect(() => {
    functionHandler.loadUpNext();
  }, []);

  const functionHandler = {
    loadUpNext() {
      setIsLoading(true);
      Api.handler.event
        .getUpNext(loginToken)
        .then((res) => {
          console.log("got response from server: ", res.data);
          const response = res.data as ResponseType;

          const events = response.message as {
            active: HbType.EventResponseType[];
            next: HbType.EventResponseType[];
          };

          setActiveEvents(events.active);
          setUpnextEvent(events.next);
        })
        .catch((err) => {
          console.log("error on getting response: ", err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      // bgcolor={isDetentioned ? "#fed1adff" : "transparent"}
      flexWrap="wrap"
    >
      {isLoading && <LoadingView />}
      {activeEvents && activeEvents?.length > 0 ? (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          flexWrap="wrap"
        >
          <Typography
            mt={4}
            color={Constants.colors.lightTheme.palette.primary.main}
            variant="h6"
            sx={{ fontWeight: "bold", textTransform: "uppercase" }}
          >
            Active events
          </Typography>

          {activeEvents.map((e) => (
            <ActiveEvent key={e.id} event={e} />
          ))}
        </Box>
      ) : (
        <NoActiveEvent />
      )}
      {upNextEvent && upNextEvent?.length > 0 ? (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          flexWrap="wrap"
        >
          <Typography
            mt={4}
            color={Constants.colors.lightTheme.palette.primary.main}
            variant="h6"
            sx={{ fontWeight: "bold", textTransform: "uppercase" }}
          >
            Upcoming events
          </Typography>

          {upNextEvent.map((e) => (
            <UpcomingEvent key={e.id} event={e} />
          ))}
        </Box>
      ) : (
        <NoUpcomingEvent />
      )}
    </Box>
  );
}
