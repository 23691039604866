import { Avatar, Box, Typography } from "@mui/material";
import { HbType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";
import EfStyleSheet from "../../utils/style-helper";
import Utils from "../../utils/utils";
import FallbackImageView from "../fallback-image-view";

export default function DetentionListItem({
  attendance,
}: {
  attendance: HbType.AttendanceResponseType;
}) {
  const styles = EfStyleSheet.create({
    root: {
      flexWrap: "wrap",
      width: "100%",
      mt: 1,
      mb: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 1,
      p: 1,
      borderRadius: 2,
      backgroundColor:
        attendance.det_enter === "skipped"
          ? "#c2c2c2c1"
          : attendance.det_enter === ""
          ? "#ffba60cf"
          : "#f86a5bd1",
      "&:hover": {
        backgroundColor:
          (attendance.status === "present"
            ? Constants.colors.lightTheme.palette.success.main
            : attendance.status === "late"
            ? Constants.colors.lightTheme.palette.warning.main
            : Constants.colors.lightTheme.palette.error.main) + "30",
      },
    },
    avatar: {
      backgroundColor:
        Constants.colors.lightTheme.palette.secondary.main + "80",
    },
    center: {
      flexGrow: 1,
    },
    action: {
      height: "100%",
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    status: {
      width: "fit-content",
      padding: "2px 8px",
      borderRadius: 4,
      color: "white",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 10,
      backgroundColor:
        attendance.status === "present"
          ? Constants.colors.lightTheme.palette.success.main
          : attendance.status === "late"
          ? Constants.colors.lightTheme.palette.warning.main
          : Constants.colors.lightTheme.palette.error.main,
    },
    detentioned: {
      width: "fit-content",
      padding: "2px 8px",
      borderRadius: 4,
      color: "white",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 10,
      display: attendance.status === "absent" ? "none" : "flex",
      backgroundColor:
        attendance.detentioned === "yes"
          ? Constants.colors.lightTheme.palette.error.main
          : Constants.colors.lightTheme.palette.success.main,
    },
  });
  return (
    <Box sx={styles.root}>
      <Box>
        <Avatar sx={styles.avatar}>
          <FallbackImageView
            src={Utils.retrieveImageURL(attendance.student.profile_url)}
            fallback={Constants.images.student}
          />
        </Avatar>
      </Box>
      <Box sx={styles.center}>
        <Typography
          variant="h6"
          sx={{ color: Constants.colors.lightTheme.palette.primary.main }}
        >
          {attendance.className}
          <span style={{ fontSize: 10 }}> - {attendance.det_from}</span>
        </Typography>
        <Box
          display="flex"
          gap={1}
          alignItems="center"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          <Typography sx={styles.status}>{attendance.subjectName}</Typography>
          <Typography sx={styles.detentioned}>
            by {attendance.teacherName}
          </Typography>
        </Box>
      </Box>
      <Typography>{attendance.det_reason}</Typography>
      <Box sx={{ width: "100%", flexGrow: 1 }}>
        <Typography
          variant="subtitle2"
          sx={{
            color: Constants.colors.lightTheme.palette.primary.main,
            backgroundColor: "#fff",
            borderRadius: 4,
            border: "1px solid red",
            m: 0.1,
            p: 0.1,
            textAlign: "center",
          }}
        >
          Status:{" "}
          {attendance.det_enter === "skipped"
            ? "Skipped"
            : attendance.det_enter === ""
            ? "Queued"
            : "Detentioned"}
        </Typography>
      </Box>
    </Box>
  );
}
