import axios from "axios";

//TODO: always prepare api from Api.handler.{functionName}()
// const endPointUrl = "http://2.2.2.103:8033";

const endPointUrl = "https://napi.ism.ly";

const Api = {
  // endPointUrl: "https://ismschoool.com/efortshub",
  endpoints: {
    //get
    uploads: endPointUrl,
    libyaTime: endPointUrl + "/lt",
    //post
    login: (type: string) => endPointUrl + "/auth/" + type,
    //get
    validate: (token: string) =>
      endPointUrl + "/auth/validate/student/" + token,
    event: {
      //get
      getActive(token: string, eventId: number) {
        return endPointUrl + "/student/active-event/" + eventId + "/" + token;
      },
      //get
      getUpNext(token: string) {
        return endPointUrl + "/student/upnext/" + token;
      },
    },
    attendance: {
      //get
      getAll(token: string, eventId: number) {
        return endPointUrl + "/student/attendance/" + eventId + "/" + token;
      },
      //get
      amIDetentioned(token: string) {
        return endPointUrl + "/student/am-i-detentioned/" + token;
      },
      //post
      udpate(token: string) {
        return endPointUrl + "/student/attendance/update/" + token;
      },
    },
  },
  handler: {
    login(email: string, password: string, token: string, type: string) {
      return axios.post(Api.endpoints.login(type), {
        email: email,
        password: password,
        token: token,
      });
    },
    validate(loginToken: string) {
      return axios.get(Api.endpoints.validate(loginToken));
    },
    event: {
      getActive(token: string, eventId: number) {
        return axios.get(Api.endpoints.event.getActive(token, eventId));
      },
      getUpNext(token: string) {
        return axios.get(Api.endpoints.event.getUpNext(token));
      },
    },
    attendance: {
      getAll(token: string, eventId: number) {
        return axios.get(Api.endpoints.attendance.getAll(token, eventId));
      },
      amIDetentioned(token: string) {
        return axios.get(Api.endpoints.attendance.amIDetentioned(token));
      },
      update(
        eventId: number,
        gradeId: number,
        subjectId: number,
        qrValue: string,
        token: string
      ) {
        return axios.post(Api.endpoints.attendance.udpate(token), {
          event_id: eventId,
          grade_id: gradeId,
          subject_id: subjectId,
          qr_value: qrValue,
        });
      },
    },
  },
};

export default Api;
